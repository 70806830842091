<template>
  <div class="d-flex align-items-center ml-login__wrapper">
    <div
      class="
        d-md-flex d-none
        flex-column
        justify-content-between
        col-6
        ml-login__image-wrapper
      "
    >
      <div class="d-flex flex-column justify-content-center content p-5 h-100">
        <h2 class="mb-3">
          Medis sistem za<br />
          nagrajevanje zvestobe
        </h2>
        <h5>
          Urejanje, pregled in kreiranje nagrajevalnih<br />
          shem medis spletnih trgovin.
        </h5>
        <span class="ml-login__rights">
          Vse pravice pridržane Medis {{ currentYear }}
        </span>
      </div>
    </div>
    <div
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        col-md-6 col-12
      "
    >
      <div
        class="d-flex flex-column align-items-center col-lg-5 col-md-6 col-12"
      >
        <h2 class="mb-2">Dobrodošli!</h2>
        <login-form />
      </div>
    </div>
    <span class="ml-login__app"
      >Razvijalci
      <a href="https://www.higroup.si" target="_blank">HiGroup d.o.o.</a></span
    >
  </div>
</template>

<script>
import LoginForm from "../components/login/LoginForm";
export default {
  name: "Login",
  components: { LoginForm },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    if (this.user !== null) this.$router.push("/products");
  },
};
</script>

<style scoped></style>

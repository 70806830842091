<template>
  <validation-observer tag="div" ref="login" class="d-flex flex-column w-100">
    <validation-provider
      tag="label"
      for="cy-email"
      rules="email|required"
      name="email"
      class="mb-3"
      v-slot="{ errors }"
    >
      <input
        id="cy-email"
        v-model="email"
        type="email"
        name="email"
        placeholder="Vnesite e-poštni naslov"
        class="form-control"
      />
      <span class="text-danger error">{{ errors[0] }}</span>
    </validation-provider>
    <validation-provider
      tag="label"
      for="cy-password"
      rules="required"
      name="geslo"
      class="mb-3"
      v-slot="{ errors }"
    >
      <input
        id="cy-password"
        v-model="password"
        type="password"
        name="password"
        placeholder="Vnesite geslo"
        class="form-control"
        @keydown.enter="login"
      />
      <span class="text-danger error">{{ errors[0] }}</span>
    </validation-provider>
    <b-button
      id="cy-submit"
      variant="primary"
      class="ml-login__submit"
      @click="login"
      :disabled="isLoading"
      >Prijava</b-button
    >
  </validation-observer>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    login() {
      this.$refs.login.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            const res = await this.$axios.post("/auth/login", {
              email: this.email,
              password: this.password,
            });
            this.$store.commit("SET_USER_DATA", res.data.user);
            await this.$router.push("/products");
          } catch (e) {
            console.error(e);
            const errors = e.response.data.errors;
            if (errors) {
              Object.keys(errors).forEach((errorKey) => {
                this.$bvToast.toast(errors[errorKey][0], {
                  title: "Napaka ob prijavi",
                  variant: "danger",
                });
              });
            } else {
              this.$bvToast.toast(e.response.data.message, {
                title: "Napaka ob prijavi",
                variant: "danger",
              });
            }
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
